import React, { Component } from "react";
import Person from "./Person";
import people from "../../contents/Team";

class People extends Component {
  render() {
    return (
      <div className="row justify-content-center">
        {people
          .sort(dynamicSort("big"))
          .sort(dynamicSort("name"))
          .map(person => (
            <Person
              big={person.big}
              key={person.name}
              id={person.id}
              name={person.name}
              title={person.title}
              picture={person.picture}
              flag={person.flag}
              facebook={person.facebook}
              github={person.github}
              instagram={person.instagram}
              linkedin={person.linkedin}
              twitter={person.twitter}
            />
          ))}
      </div>
    );
  }
}

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function(a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export default People;
