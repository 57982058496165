import React, { Component } from "react";
import "font-awesome/css/font-awesome.min.css";

class Person extends Component {
  //need to align stuff
  render() {
    const {
      big,
      id,
      name,
      title,
      picture,
      flag,
      linkedin,
      facebook,
      github,
      twitter,
      instagram
    } = this.props;
    return (
      <div
        key={id}
        className={
          "text-center " +
          (big ? "col-md-6 col-lg-3" : "col-6 col-md-4 col-lg-2")
        }
      >
        <div className="card mb-4">
          <div className="card-body" style={{backgroundColor: "#FFF3E6"}}
>
            <img
              className="card-img-top round-corners mb-1"
              src={picture}
              alt={"Picture of " + name}
            />
            <h5 className="card-title text-gray" style={{ height: "50px" }}>
              {name}
            </h5>
            <div className="card-text">
              <p className="col text-gray" style={{ height: "40px" }}>
                {title}
              </p>
              <div className="col mb-2">
                <img
                  className="col align-centre mb-1"
                  style={{ height: "40px", width: "100px" }}
                  src={flag}
                  alt={"country"}
                />
              </div>
              <div style={{ fontSize: "1.4em col" }}>
                <a className={facebook ? "" : "d-none"} href={facebook}>
                  <i className="fa fa-facebook mr-1" />
                </a>
                <a className={linkedin ? "" : "d-none"} href={linkedin}>
                  <i className="fa fa-linkedin m-1" />
                </a>
                <a className={github ? "" : "d-none"} href={github}>
                  <i className="fa fa-github m-1" />
                </a>
                <a className={instagram ? "" : "d-none"} href={instagram}>
                  <i className="fa fa-instagram m-1" />
                </a>
                <a className={twitter ? "" : "d-none"} href={twitter}>
                  <i className="fa fa-twitter m-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Person;
