/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from "react";

export default [
  {
    question: <Fragment> What is IC Hack? </Fragment>,
    answer: (
      <Fragment>
        <p>
          IC Hack is Imperial College's Annual Hackathon, back bigger than ever
          for a new edition.
        </p>
      </Fragment>
    )
  },
  {
    question: <Fragment>When/where is it happening? </Fragment>,
    answer: (
      <Fragment>
        <p>
          IC Hack 22 will be held on the 5th and 6th of February 2022, at the Imperial College London South Kensington Campus.
        </p>
      </Fragment>
    )
  },
  {
    question: <Fragment>How are we protecting our attendees from COVID-19 while having our event entirely in-person? </Fragment>,
    answer: (
      <Fragment>
        <p>
          We require our attendees to be: 
        </p>
        <div class="list">
          <li>fully vaccinated (boosters not required) with a UK-approved COVID-19 Vaccine at least 14 days before the event (Feb 5th)</li>
        </div>
          <p><strong>AND</strong></p>
        <div class="list">
          <li>Showing proof of a negative COVID-19 PCR/LFD test done within 48 hours of the start time (Feb 5th 10am)</li>
        </div>
        <p>
          More details in our <nbsp></nbsp>
          <a href="https://ichack.org/covid19" rel="noreferrer">
            COVID-19 Guideline
          </a>
        </p>
      </Fragment>
    )
  },
  {
    question: <Fragment>How do I get a ticket? </Fragment>,
    answer: (
      <Fragment>
        <p>
          Everyone is free to participate as long as they can get a ticket.
        </p>
        <p>
          Tickets will be released on January 14th and 21st at 1:30 pm on our <nbsp></nbsp><a href="https://ichack.org/tickets" rel="noreferrer">
            EventBrite
          </a>.
        </p>
        <p>Make sure to snatch your tickets on time. They usually sell out in seconds! Bots are strictly <strong>not</strong> allowed, humans only. </p> 
        <p>Tickets are strictly not transferable due to fairness. We will release cancelled tickets to the waitlist on EventBrite.</p>
      </Fragment>
    )
  },
  {
    question: <Fragment> How much does it cost to enter?</Fragment>,
    answer: (
      <Fragment>
        <p>Absolutely nothing! </p>
        <p>
          Thanks to our wonderful sponsors, IC Hack is completely free for
          participants.
        </p>
        <p>That includes food to keep you going throughout the weekend.</p>
      </Fragment>
    )
  },
  {
    question: <Fragment> Who can attend?</Fragment>,
    answer: (
      <Fragment>
        <p>If you have a ticket, you are more than welcome to join us, provided that:</p>
        <div class="list">
          <li>You are a current student from any university.</li>
          <li>Or that you have graduated within a year!</li>
        </div>

        <p>We welcome anyone from any degree discipline, technical or not, to enjoy IC Hack and show off your skills throughout the weekend.</p>
        <p>
          Unfortunately, due to venue and legal constraints, we cannot host
          under 18s.
        </p>
      </Fragment>
    )
  },
  {
    question: <Fragment> What if I don't have a team?</Fragment>,
    answer: (
      <Fragment>
        <p>
          Don't worry, we'll have a Slack channel for participants to form teams
          before the event.
        </p>
        <p>You can also form a team when you arrive on the Saturday morning.</p>
      </Fragment>
    )
  },
  {
    question: <Fragment> How big should the teams be?</Fragment>,
    answer: (
      <Fragment>
        <p>The maximum amount of people allowed in a team is 5.</p>
      </Fragment>
    )
  },
  {
    question: (
      <Fragment> Do I need loads of experience to participate?</Fragment>
    ),
    answer: (
      <Fragment>
        <p>
          Not at all. Whether you are a first year student, study a subject
          unrelated to computing, or this is your first Hackathon, you are still
          welcome to enter IC Hack.
        </p>
        <p>
          This is also a great opportunity to learn and gain new experience!
        </p>
      </Fragment>
    )
  },
  {
    question: <Fragment> Can I help organise IC Hack?</Fragment>,
    answer: (
      <Fragment>
        <p>
          Of course, we're always looking for volunteers to help organise.
        </p>
        <p>
          Volunteers can still participate in the competition. Volunteers will
          receive extra goodies in exchange for their time. 
        </p>
        <p>Alternatively, didn't get a ticket but still want to join? Help us out on the day!
        </p>
        <p>
          Just email us at <a href="mailto:ichack@ic.ac.uk">ichack@ic.ac.uk</a>{" "}
          if you're interested.
        </p>
      </Fragment>
    )
  },
  {
    question: <Fragment> What is the code of conduct?</Fragment>,
    answer: (
      <Fragment>
        <p>
          You can find our code of conduct here:{" "}
          <a href="https://ichack.org/codeofconduct" target="_blank">
            ichack.org/codeofconduct
          </a>
        </p>
      </Fragment>
    )
  },
  {
    question: <Fragment> Got more questions?</Fragment>,
    answer: (
      <Fragment>
        <p>
          Email us at <a href="mailto:ichack@ic.ac.uk">ichack@ic.ac.uk</a>!
        </p>
      </Fragment>
    )
  }
];
