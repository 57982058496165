/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component, Fragment } from "react";
import { Jumbotron } from "react-bootstrap";
import Logo from "../assets/logo.png";
import downArrow from "../assets/down_arrow.svg";
import * as ReactDOM from "react-dom";
import Countdown from "react-countdown-now";

class Header extends Component {
  constructor(props) {
    const { firstSection } = props;
    super(props);
    this.firstSectionRef = firstSection;
    this.state = {
      mute: true,
      video: null
    };
  }
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.mute();
    this.setState({ video: event.target });
  }
  toggleMute() {
    const currentState = this.state.mute;
    this.setState({ mute: !currentState });
    if (this.state.video) {
      if (!currentState) {
        this.state.video.mute();
      } else {
        this.state.video.unMute();
      }
    }
  }

  render() {
    const opts = {
      playerVars: {
        autoplay: 1,
        version: 3,
        controls: 0,
        loop: 1,
        playlist: "Oy6tcQY6K6Q",
        end: 136
      }
    };
    return (
      <Fragment>
        <div id={"page-top-wrapper"}>
          <div id={"page-top-content"}>
            <Jumbotron id="jumbotron">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-5">
                  <img
                    src={Logo}
                    alt={"IC Hack"}
                    className={"logo-shadow col-12"}
                  />
                </div>
              </div>
              <div id="main-event-details">
                <div className="row justify-content-center mb-2">
                  <div className="col-12 col-md-8 col-lg-5 h2 font-weight-bold">
                    {"5 - 6 February 2022"}
                    <br />
                    Imperial College London
                  </div>
                </div>
                <h1 className="display-5 justify-content-center row font-weight-bold mb-5">
                  {<Countdown
                    date="Sat, 5 Feb 2022 10:00:00"
                    daysInHours={false}
                    renderer={countdownRenderer}
                  />}
                </h1>
                <div className="row justify-content-center">
                  <a
                    href="https://fb.me/e/1i9gS2hzM"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      width: "60px",
                      height: "60px"
                    }}
                    className="btn btn-social-icon btn-lg btn-facebook mr-2 p-4"
                  >
                    <span className="fa fa-facebook p-2" />
                  </a>
                  <a
                    href="https://ic-hack-2022.devpost.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    role="button"
                    aria-pressed="true"
                    className="btn btn-secondary btn-lg"
                    style={{
                      backgroundColor: "#ffad4f",
                      borderColor: "#ffad4f",
                      color: "black",
                      height: "60px",
                      fontSize: "1em",
                      paddingTop: "3px",
                      fontWeight: "bold",
                      marginRight: '7.5px'
                    }}
                  >
                    <span style={{ fontSize: "2em" }}>Devpost</span>
                  </a>
                  <a
                    href="https://www.instagram.com/ichackuk"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      width: "60px",
                      height: "60px"
                    }}
                    className="btn btn-social-icon btn-lg btn-instagram mh-2 p-4"
                  >
                    <span className="fa fa-instagram p-2" />
                  </a>
                </div>

                <div className="mt-3 m-lg-4" style={{ textAlign: "center" }}>
                  <img
                    onClick={() => {
                      const firstSectionNode = ReactDOM.findDOMNode(
                        this.firstSectionRef.current
                      );
                      firstSectionNode.scrollIntoView({ behavior: "smooth" });
                    }}
                    src={downArrow}
                    alt={"logo"}
                    id="down-arrow"
                  />
                </div>
              </div>
            </Jumbotron>
          </div>
        </div>
      </Fragment>
    );
  }
}

const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <div>See you next year!</div>;
  } else {
    // Render a countdown
    return (
      <div className="col-12">
        <span>
          {days}
          <small> Days </small>
          {hours}
          <small> Hours </small>
        </span>
        <br className="hideLarge"></br>
        <span>
          {minutes}
          <small> Mins </small>
          {seconds}
          <small> Secs </small>
        </span>
      </div>
    );
  }
};

export default Header;
