import React, { Component, Fragment } from "react";

import "../styles.css";

import Header from "./Header";
import Title from "./Title";
import FAQ from "./FAQ";
import Sponsors from "./Sponsors";
import Team from "./Team";
import Footer from "./Footer";

class ICHack extends Component {
  constructor(props) {
    super(props);
    this.firstSectionRef = React.createRef();
  }
  render() {
    return (
      <Fragment>
        <Header firstSection={this.firstSectionRef} />
        <Title firstSection={this.firstSectionRef} />
        <FAQ />
        <Sponsors />
        <Team />
        <Footer />
      </Fragment>
    );
  }
}

export default ICHack;
