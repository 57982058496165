/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import timeline from "../../contents/Timeline";

export default class Timeline extends Component {
  render() {
    return (
      <div className="container">
        <h1 className="font-weight-bold text-gray">Schedule</h1>
        <div className="row justify-content-center">
          {timeline.map(day => (
            <div className="col-md-6 col-12 mt-4 text-gray" key={day.day}>
              <div className="row justify-content-center">
                <div className="col-12 row justify-content-center">
                  <h4 className="col-12 col-md-10 row text-gray">{day.day}</h4>
                  <h3
                    className="col-12 col-md-10 row mb-4 text-gray"
                    style={{ borderBottom: "1px solid #333" }}
                  >
                    {day.date}
                  </h3>
                  <div className="col-12 row events">
                    {day.events.map(event => (
                      <div
                        className="col-12 row mt-1 p-0 text-gray"
                        key={event.title}
                      >
                        <h5
                          style={{ fontSize: "1.2rem" }}
                          className="col-3 p-0 text-gray"
                        >
                          {event.time}
                        </h5>
                        <div className="col-1 p-0" style={{ zIndex: "3" }}>
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              backgroundColor: event.color,
                              borderRadius: "50%",
                              marginTop: "5px",
                              zIndex: "3"
                            }}
                          />
                        </div>
                        <h5
                          style={{ fontSize: "1.2rem" }}
                          className="col text-left p-0 text-gray"
                        >
                          {event.title}
                        </h5>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
