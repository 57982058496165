import React, { Component } from "react";
import Section from "./sub-components/Section";
import Timeline from "./sub-components/Timeline";
import WhatIsICHack from "./sub-components/WhatIsICHack";

class Title extends Component {
  constructor(props) {
    super(props);
    this.firstSectionRef = props.firstSection;
  }

  render() {
    return (
      <Section colour={"#ffad4f"} other="triangles">
        <div ref={this.firstSectionRef} />
        <div className="row justify-content-center pt-5 text-center">
          <div className="col-12 font-weight-bold">
            <h1 className="font-weight-bold text-gray">IC Hack 2022!</h1>
          </div>
          <div className="col-12">
            <p className="text-gray">
              The largest student-run hackathon in the UK is back, bigger and
              better than ever!
            </p>
          </div>
          {/* <div className="col-12">
            <h2>
              Go to the Hub for more Information, Hardware Booking, Mentor
              Requests, Slack, Devpost and more!
            </h2>
          </div> */}

          <div className="col-11 mb-2">
            <WhatIsICHack />
          </div>

          {/* <div className="col-11 mb-2 mt-5">
            <h2 className="font-weight-bold text-gray">Last Year Pictures</h2>
            <a
              href="https://www.flickr.com/photos/paulmonishbalaji/sets/72157706623151464/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: "150px",
                height: "60px"
              }}
              className="btn btn-social-icon btn-lg btn-flickr ml-2 p-4"
            >
              <span className="fa fa-flickr p-2">
                {" "}
                <span style={{ fontFamily: "Source Sans Pro, sans-serif" }}>
                  Flickr
                </span>
              </span>
            </a>
          </div> */}
          <div className="col-11 mt-5">
            <Timeline />
          </div>
        </div>
      </Section>
    );
  }
}

export default Title;
