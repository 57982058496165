import React, { Component } from "react";
import Section from "./sub-components/Section";

class Footer extends Component {
  render() {
    return (
      <Section colour={"#333"}>
        <div className="pt-4">
          <div style={{ textAlign: "center", color: "white" }}>
            <a href={"https://20.ichack.org/"}>Last ICHack 20</a>
          </div>
          <div style={{ textAlign: "center", color: "white" }}>
            IC Hack is organised by <a href={"https://docsoc.co.uk"}>DoCSoc</a>,
            the Imperial College London Department of Computing Society.
            <br />
            &copy; 2022 Imperial College DoCSoc.
          </div>
        </div>
      </Section>
    );
  }
}

export default Footer;
