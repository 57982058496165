import React, { Component } from "react";
import Section from "./sub-components/Section";
import People from "./sub-components/People";

class Team extends Component {
  render() {
    return (
      <Section colour="">
        <div className="row justify-content-center pt-5">
          <h2 className="col-10 col-md-11 mb-3 font-weight-bold">
            Meet the organisers!
          </h2>
          <div className="col-10 col-lg-11">
            <People />
          </div>
        </div>
      </Section>
    );
  }
}

export default Team;
