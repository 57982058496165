import React, { Component } from "react";

class WhatIsICHack extends Component {
  render() {
    return (
      <div>
        <div className="col-12 mb-3 mt-5 ">
          <h2 className="font-weight-bold text-gray">
            What happens at IC Hack?
          </h2>
        </div>
        <div className="col-12 row justify-content-center m-0">
          <div className="col-6 mb-1 text-center">
            <img
              className="float-lg-right"
              width="30px"
              alt="hacking"
              src="/images/icons/hacking.svg"
            />
            <h4 className="float-lg-right mr-md-2 text-gray">
              {" "}
              24 Hours Hacking
            </h4>
          </div>
          <div className="col-6 mb-1 text-center">
            <img
              className="float-lg-left"
              width="30px"
              alt="prize"
              src="/images/icons/prize.svg"
            />
            <h4 className="float-lg-left ml-md-2 text-gray"> Amazing Prizes</h4>
          </div>

          <div className="col-6 mb-1">
            <img
              className="float-lg-right"
              width="30px"
              alt="entertainment"
              src="/images/icons/entertainment.svg"
            />
            <h4 className="float-lg-right mr-md-2 text-gray">
              {" "}
              Karaoke & Games
            </h4>
          </div>
          <div className="col-6 mb-1">
            <img
              className="float-lg-left"
              width="30px"
              alt="pizza"
              src="/images/icons/pizza.svg"
            />
            <h4 className="float-lg-left ml-md-2 text-gray"> Food & Drinks</h4>
          </div>

          <div className="col-6 mb-1">
            <img
              className="float-lg-right"
              width="30px"
              alt="workshop"
              src="/images/icons/workshop.svg"
            />
            <h4 className="float-lg-right mr-md-2 text-gray">
              {" "}
              Great Workshops{" "}
            </h4>
          </div>
          <div className="col-6 mb-1">
            <img
              className="float-lg-left"
              width="30px"
              alt="drink"
              src="/images/icons/tote-bag.svg"
            />
            <h4 className="float-lg-left ml-md-2 text-gray">
              {" "}
              Lots Of Free Swag
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default WhatIsICHack;
