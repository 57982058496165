import React, { Component } from "react";

let size = 3;

class question extends Component {
  render() {
    const { question, answer } = this.props;
    return (
      <div
        style={{ borderRadius: "1rem" }}
        className={
          "card question mb-3 ml-lg-2 mr-lg-2 col-10 col-lg-" + this.getSize()
        }
      >
        <div className="card-body">
          <h5 className="card-title">{question}</h5>
          <div className="card-text">{answer}</div>
        </div>
      </div>
    );
  }

  getSize() {
    if (size < 2) {
      size++;
      return 7;
    } else if (size < 3) {
      size++;
      return 4;
    } else {
      size = 0;
      return 4;
    }
  }
}

export default question;
