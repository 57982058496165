import React, { Component } from "react";
import Section from "./sub-components/Section";

import sponsors from "../contents/Sponsors.json";

class Sponsors extends Component {
  render() {
    return (
      <Section colour={"#fff"} other="">
        <div className="row justify-content-center pt-3">
          <h1 className="col-10 col-lg-11 mb-3 font-weight-bold">Sponsors</h1>
          {/* <h2 className="col-10 col-lg-11 text-gray">
            If you are interested in sponsoring us, send us an email at{" "}
            <a href="mailto:ichack@ic.ac.uk">ichack@ic.ac.uk</a>
          </h2> */}
        </div>

        {sponsors.map(t => {
          return (
            <div key={t.rank} className="container">
              {t.sponsors.length !== 0 && (
                <div className="row justify-content-center mb-3">
                  <div className="col-10 col-lg-11">
                    <div className="d-flex flex-row align-items-start ">
                      <img
                        alt={t.img}
                        src={t.img}
                        style={{ width: "100px" }}
                        className="p-2"
                      />
                      <h2 className="p-2 text-black">{t.rank}</h2>
                    </div>
                  </div>
                </div>
              )}
              <div className="row justify-content-center">
                <div className="col-10 col-lg-11 row justify-content-center align-items-end">
                  {t.sponsors.map(s => {
                    return (
                      <div
                        key={s.Name}
                        className="col-10 col-md-6 col-lg-3 text-center align-bottom ma-2 mt-3"
                      >
                        <img
                          alt={s.Logo}
                          src={s.Logo}
                          className="img-fluid col-12 mb-2 pb-3 border-bottom align-bottom"
                        />
                        <h5
                          className="align-bottom text-black"
                          style={{ minHeight: "60px" }}
                        >
                          {s.Name}
                        </h5>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </Section>
    );
  }
}

export default Sponsors;
