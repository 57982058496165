import React, { Component } from "react";

export default class Section extends Component {
  render() {
    const { children, colour, other } = this.props;
    return (
      <div style={{ backgroundColor: colour }} className={other + " section"}>
        <div className="container">{children}</div>
      </div>
    );
  }
}

Section.defaultProps = {
  colour: "black"
};
