import React, { Component } from "react";

import Question from "./sub-components/Question";
import Section from "./sub-components/Section";

import FAQArray from "../contents/FAQ";

let i = 0;

class FAQ extends Component {
  render() {
    return (
      <Section colour={""}>
        <div className="pt-5">
          <img
            className="float-left ml-4 mr-2"
            width="40px"
            alt="entertainment"
            src="/images/icons/life-ring.svg"
          />
          <h1 className="col-11 d-none d-md-block font-weight-bold ml-2">
            Frequently Asked Questions
          </h1>
          <h1 className="col-11 d-md-none d-block font-weight-bold">FAQ</h1>
          <div className="row justify-content-center">
            {FAQArray.map(q => (
              <Question question={q.question} answer={q.answer} key={i++} />
            ))}
          </div>
        </div>
      </Section>
    );
  }
}

export default FAQ;
