export default [
  {
    id: "1",
    name: "Sebastian Males",
    picture: "images/team/sebastian-males.jpg",
    title: "3rd Year Computing",
    facebook: "",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/sebastian-males/",
    github: "",
    instagram: "",
    flag: "images/flags/romania.svg",
    big: true,
  },
  {
    id: "2",
    name: "Karan Obhrai",
    picture: "images/team/karan-obhrai.jpg",
    title: "3rd Year Computing",
    facebook: "https://www.facebook.com/karan.obhrai.5/",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/karan-obhrai-724556182/",
    github: "",
    instagram: "",
    flag: "images/flags/uk.svg",
    big: true,
  },
  {
    id: "3",
    name: "Panayiotis Gavriil",
    picture: "images/team/panayiotis-gavriil.jpg",
    title: "2nd Year Computing",
    facebook: "",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/panayiotisgavriil",
    github: "",
    instagram: "",
    flag: "images/flags/cyprus.svg",
    big: true,
  },
  {
    id: "4",
    name: "Chloe Lau",
    picture: "images/team/chloe-lau.jpg",
    title: "3rd Year Computing",
    facebook: "https://www.facebook.com/thatcompscigirl/",
    twitter: "https://twitter.com/cholelwt",
    linkedin: "https://www.linkedin.com/in/chloewtlau/",
    github: "https://github.com/chloelaucodes",
    instagram: "",
    flag: "images/flags/HK.svg",
    big: true,
  },
  {
    id: "5",
    name: "Stefan Radziuk",
    picture: "images/team/stefan-radziuk.jpg",
    title: "3rd Year Computing",
    facebook: "",
    twitter: "",
    linkedin: "https://linkedin.com/in/stefanradziuk",
    github: "",
    instagram: "",
    flag: "images/flags/poland.svg",
    big: true,
  },
  {
    id: "6",
    name: "Ioana Mihailescu",
    picture: "images/team/ioana-mihailescu.jpg",
    title: "2nd Year Computing",
    facebook: "https://www.facebook.com/ioana.mihailescu.000",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/ioanamih",
    github: "",
    instagram: "",
    flag: "images/flags/romania.svg",
    big: true,
  },
  {
    id: "7",
    name: "Jordan Hall",
    picture: "images/team/jordan-hall.jpg",
    title: "2nd Year Computing",
    facebook: "",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/jordan-hall-449235108",
    github: "",
    instagram: "",
    flag: "images/flags/wales.svg",
    big: true,
  },
  {
    id: "8",
    name: "Jason Bae",
    picture: "images/team/jason-bae.jpg",
    title: "3rd Year Computing",
    facebook: "",
    twitter: "",
    linkedin: "www.linkedin.com/in/junhyukjasonbae",
    github: "",
    instagram: "",
    flag: "images/flags/rok.svg",
    big: true,
  },
  {
    id: "9",
    name: "Shashwat Kansal",
    picture: "images/team/shashwat-kansal.jpg",
    title: "3rd Year Computing",
    facebook: "https://www.facebook.com/shashwat.kansal",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/shashwatkansal",
    github: "",
    instagram: "",
    flag: "images/flags/india.svg",
    big: true,
  },
  {
    id: "12",
    name: "Noor Sawhney",
    picture: "images/team/noor-sawhney.jpg",
    title: "3rd Year Computing",
    facebook: "https://www.facebook.com/noor.sawhney",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/noor-sawhney-755b1b19a/",
    github: "",
    instagram: "",
    flag: "images/flags/uk.svg",
    big: true,
  },
  {
    id: "15",
    name: "Dyuti Chakraborty",
    picture: "images/team/dyuti-chakraborty.jpg",
    title: "3rd Year Computing",
    facebook: "",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/dyuti-chakraborty/",
    github: "",
    instagram: "",
    flag: "images/flags/uk.svg",
    big: true,
  },
  {
    id: "16",
    name: "Daniel Ababei",
    picture: "images/team/daniel-ababei.jpg",
    title: "3rd Year Computing",
    facebook: "https://www.facebook.com/profile.php?id=100010163253912",
    twitter: "https://twitter.com/DanielAbabei99",
    linkedin: "https://www.linkedin.com/in/daniel-ababei/",
    github: "",
    instagram: "",
    flag: "images/flags/romania.svg",
    big: true,
  },
  {
    id: "17",
    name: "Allan Lee",
    picture: "images/team/allan-lee.jpg",
    title: "4th Year Biomedical Engineering",
    facebook: "https://www.facebook.com/hon.lee.5070276",
    twitter: "https://twitter.com/allanlhk",
    linkedin: "https://www.linkedin.com/in/hon-kwan-allan-l-3035a4135/",
    github: "",
    instagram: "",
    flag: "images/flags/uk.svg",
    big: true,
  },
  {
    id: "18",
    name: "Kia Popat",
    picture: "images/team/kia-popat.jpg",
    title: "2nd Year EIE",
    facebook: "",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/kiapopat/",
    github: "https://github.com/kiapopat/",
    instagram: "",
    flag: "images/flags/uk.svg",
    big: true,
  },
  {
    id: "19",
    name: "Hilliam Tung",
    picture: "images/team/hilliam-tung.jpg",
    title: "4th Year JMC",
    facebook: "https://www.facebook.com/hilliam.tu.1",
    twitter: "https://twitter.com/hilliamtung",
    linkedin: "https://www.linkedin.com/in/hilliamtung/",
    github: "https://github.com/HilliamT",
    instagram: "",
    flag: "images/flags/uk.svg",
    big: true,
  },
  {
    id: "20",
    name: "Adrian Wong",
    picture: "images/team/adrian-wong.jpg",
    title: "2nd Year JMC",
    facebook: "",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/adrian-wong-b121a0158/",
    github: "https://github.com/N00bAdrian",
    instagram: "https://www.instagram.com/n00badrian/",
    flag: "images/flags/hk.svg",
    big: true,
  },
  {
    id: "21",
    name: "Jaiditya Khemani",
    picture: "images/team/jaiditya-khemani.jpg",
    title: "1st Year Computing",
    facebook: "",
    twitter: "",
    linkedin: "www.linkedin.com/in/jaiditya-khemani",
    github: "https://github.com/marc13000",
    instagram: "https://www.instagram.com/jai03kh/",
    flag: "images/flags/india.svg",
    big: true,
  },
  {
    id: "22",
    name: "Khemi Chew",
    picture: "images/team/khemi-chew.jpg",
    title: "2nd Year Computing",
    facebook: "https://www.facebook.com/khemichew.jiajin",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/khemi-chew/",
    github: "https://github.com/khemichew",
    instagram: "",
    flag: "images/flags/malay.svg",
    big: true,
  },
];
